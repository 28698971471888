import { useEffect, useState } from "react";
import Fetch from "../controller/Fetch";
import AlertBox from "../controller/AlertBox";
import Loading from "../controller/Loading";
import { Accordion, Card } from "react-bootstrap";
import Escape from "../controller/Escape";

export default function Support(){
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState([]);
    const [Alert,setAlert]=useState();
    useEffect(()=>{
        let send={
            url:'v1/admin/qa',
            method:'GET'
        };
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error)
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        });
    },[]);
    return(
        <div>
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} msgs={[]} />
            <h2>
                よくある質問
            </h2>
            <Accordion defaultActiveKey="0">{Item.map(item=>
                <Accordion.Item key={item['QuestionId']} eventKey={item['QuestionId']}>
                    <Accordion.Header>
                        Q.{item['title']}
                    </Accordion.Header>
                    <Accordion.Body>
                        <Card>
                            <Card.Header>
                                質問本文
                            </Card.Header>
                            <Card.Body>
                                {Escape(item['content'])}
                            </Card.Body>
                        </Card>
                        <Card className="mt-2">
                            <Card.Header>
                                回答内容
                            </Card.Header>
                            <Card.Body>
                                {Escape(item['answer'])}
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            )}</Accordion>
        </div>
    );
}