import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Domain } from '../../controller/keys';
export default function Header(){
	function logout(){
		window.location.href="https://login.canteach.jp/logout/";
	}
	return(
		<header className="w-100 document__header" role="heading">
			<nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
				<div className="container-fluid">
                    <Link to="/" style={{color:'var(--basic-color)'}} className="navbar-brand">CanTeach</Link>
                    <div id="navbarGlobal" className="collapse navbar-collapse">
                        <ul className="navbar-nav me-auto mb-3 mb-lg-0"></ul>
                        <div className="d-flex">
                            <Dropdown align="end">
                                <Dropdown.Toggle variant="">
                                    <i className="fas fa-user text-gray header__user"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align={{lg:'end'}}>
                                    <Dropdown.Item href={Domain('home')}>
                                        ホーム
                                    </Dropdown.Item>
                                    <Dropdown.Item href={Domain('home','account')} target="_blank">
                                        アカウント
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <button className="btn link-secondary align-middle link-underline w-auto" onClick={logout}>
                            ログアウト
                        </button>
                    </div>
				</div>
			</nav>
		</header>
	);
}