export function createKey(){
    return Math.random().toString(32).substring(2);
}
export function Domain(type="",path=""){
    if(window.location.hostname=="localhost"){
        return `http://localhost/canteach/${type}/${path}`;
    }else{
        return `https://${type}.canteach.jp/${path}`;
    }
}
export function Host(uri=""){
    if(window.location.hostname=="localhost"){
        return 'http://'+window.location.host+'/'+uri;
    }else{
        return 'https://app.canteach.jp/'+uri;
    }
}
export function getParams(){
    let SplitUrl=window.location.href.split('?');
    let params = {}

    if (SplitUrl[1]) {
        let urlParamStr = SplitUrl[1];
        urlParamStr.split('&').forEach( param => {
            const temp = param.split('=')
            params = {
                ...params,
                [temp[0]]: decodeURI(temp[1])
            }
        });
    }
    return params;
}
export function ConvertToDatetime(key){
    let date=new Date(key);
    let month=Number(date.getMonth())+1;
    return date.getFullYear()+'-'+('00'+month).slice(-2)+'-'+('00'+date.getDate()).slice(-2)+'T'+('00'+date.getHours()).slice(-2)+':'+('00'+date.getMinutes()).slice(-2);
}
export function ConvertToDate(key,obj=false){
    let date;
    if(obj){
        date=key;
    }else{
        date=new Date(key);
    }
    let month=Number(date.getMonth())+1;
    return date.getFullYear()+'-'+('00'+month).slice(-2)+'-'+('00'+date.getDate()).slice(-2);
}
export function ConvertToDisplayDate(key,time=false,obj=false){
    let date;
    if(obj){
        date=key;
    }else{
        date=new Date(key);
    }
    let month=Number(date.getMonth())+1;
    if(time){
        return date.getFullYear()+'年'+month+'月'+date.getDate()+'日 '+date.getHours()+'時'+date.getMinutes()+'分';
    }else{
        return date.getFullYear()+'年'+month+'月'+date.getDate()+'日';
    }
}
export function number_format(number){
    return Number(number).toLocaleString( undefined, { maximumFractionDigits: 20 });
}
export function CourseFree(key){
    const vals=[
        '無料期間なし',
        "1カ月無料"
    ];
    return vals[key];
}
export function ContactType(key){
    const vals=[
        'システムからのお知らせ',
        '運営からのお知らせ',
        '広告',
        '(講師)コースからのお知らせ',
        '(受講者)コースからのお知らせ'
    ];
    return vals[key];
}
export function StudentCondition(key){
    const vals=[
        '受講中',
        '途中解約',
        '修了',
        '契約満了'
    ];
    return vals[key];
}
export function PaymentCondition(key){
    const vals=[
        '決済済み',
        "決済予約",
        "決済失敗",
        "決済削除"
    ];
    return vals[key];
}
export function FriendCondition(key){
    const vals=[
        "承諾",
        "拒否",
        "無視",
        ""
    ];
    return vals[key];
}
export function PaymentMethod(key){
    const vals=[
        "Stripe",
        "PayPay",
        "PayPal"
    ];
    return vals[key];
}
export function UserType(key){
    const vals=[
        "受講・講師",
        "受講者",
        "講師"
    ];
    return vals[key];
}
export function UserRight(key){
    const vals=[
        "一般",
        "利用料管理者",
        "総合管理者"
    ];
    return vals[key];
}
export function AdminInfoType(key){
    const vals=[
        "システムからのお知らせ",
        "運営からのお知らせ",
        "広告"
    ];
    return vals[key];
}
export function AdminInfoTo(key){
    const vals=[
        "全ユーザー",
        "受講者",
        "講師",
        "受講者のみ",
        "講師のみ",
        "受講者・講師"
    ];
    return vals[key];
}
export function PaymentMethodKey(key){
    const vals={
        'stripe':'Stripe',
        'paypay':'PayPay',
        'paypal':'PayPal'
    }
    return vals[key];
}

export function PaymentMethodImg(key){
    const vals={
        'stripe':'stripe.svg',
        'paypay':'paypay.png',
        'paypal':'paypal.png'
    }
    return 'https://canteach.jp/assets/img/'+vals[key];
}

export function PaymentMethodLink(key){
    const vals={
        'stripe':'https://stripe.com',
        'paypay':'https://paypay.ne.jp',
        'paypal':'https://paypal.com'
    }
    return vals[key];
}
export function TextProgress(key){
    const vals=[
        "視聴未完",//未読
        "視聴未完",//既読
        "視聴完了"
    ];
    return vals[key];
}